<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="`${role.name_text}'s Permissions`"
          v-bind:link="'role'"
        >
          <template v-slot:preview>
            <v-row class="text-right">
              <v-spacer></v-spacer>
              <v-col cols="12">
                <v-btn color="blue darken-1" dark @click="savePermission"
                  >Save Permission</v-btn
                >
              </v-col>
            </v-row>

            <v-card class="mt-3 p-5">
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox
                    v-if="role.name !== 'admin'"
                    v-model="status"
                    @change="selectAll()"
                    outlinde
                    dense
                    label="Select All"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="8">
                  <h5>Permission</h5>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row v-for="(permission_list, i) of permissions" :key="i">
                <v-col cols="12" md="4">
                  <label>{{ i.replace("_", " ").toUpperCase() }}</label>
                </v-col>
                <v-col cols="12" md="8">
                  <v-row v-if="permission_list.length > 0">
                    <v-col
                      cols="3"
                      v-for="(permission, j) of permission_list"
                      :key="j"
                    >
                      <v-switch
                        v-model="selected"
                        :value="permission"
                        :label="permission"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import RoleService from "@/service/user/role/RoleService";
import RoleCustomPermission from "@/service/user/role/RoleCustomPermission";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTCodePreview from "@/view/content/CodePreview";

const roleService = new RoleService();
const customPermission = new RoleCustomPermission();
export default {
  name: "ManageRoleCustomPermission",
  components: { KTCodePreview },
  data() {
    return {
      permissions: [],
      selected: [],
      role: {},
      status: false
    };
  },
  computed: {
    role_id() {
      return this.$route.params.id;
    },
    ...mapGetters(["currentUser"])
  },
  mounted() {
    this.getRole();
    this.getAllSubmenuPermissions();
    this.getAssignedPermission();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Custom Role & Permission" },
      { title: "Manage Custom Permission" }
    ]);
  },
  methods: {
    getRole() {
      roleService.show(this.role_id).then(response => {
        this.role = response.data.role;
      });
    },
    getAllSubmenuPermissions() {
      customPermission.getAllSubmenuPermissions().then(response => {
        this.permissions = response.data;
      });
    },
    getAssignedPermission() {
      customPermission.assignedPermissionToRole(this.role_id).then(response => {
        this.selected = [];
        if (response.data.permissions && response.data.permissions.length > 0) {
          response.data.permissions.forEach(ele => {
            this.selected.push(ele);
          });
        }
      });
    },
    selectAll() {
      if (this.status) {
        for (let permission in this.permissions) {
          for (let list of this.permissions[permission]) {
            this.selected.push(list);
          }
        }
      } else {
        this.selected = [];
      }
    },
    savePermission() {
      let data = { role: this.role.name, permissions: this.selected };
      customPermission
        .savePermission(data)
        .then(response => {
          if (response.data.status === "OK") {
            this.$snotify.success("Permission Assigned To Role Successfully");
            this.getAssignedPermission();
          }
        })
        .catch(() => {
          this.$snotify.error("Something Went Wrong");
        });
    }
  }
};
</script>

<style scoped></style>
